import * as Yup from "yup"

export const values = {
  reason: "",
}

export const validation = Yup.object().shape({
  reason: Yup.string().required("Campo obrigatório"),
})

export default {
  values,
  validation,
}
