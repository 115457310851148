import { useCallback, useEffect, useState } from "react"
import _pagination from "utils/pagination"
import services from "services"

const useProposals = ({ business_id = "1" }) => {
  const [proposals, setProposals] = useState([])
  const [lastUpdated, setLastUpdated] = useState(null)
  const [status, setStatus] = useState([])
  const [pagination, setPagination] = useState({})
  const [filters, setFilters] = useState({})

  const handleFetch = useCallback(
    ({ last_updated_at, status_count, paginated_result }) => {
      setLastUpdated(last_updated_at)
      setStatus(status_count)
      setProposals(paginated_result?.data)
      setPagination({
        ...paginated_result?.pagination,
        total_pages: _pagination.getTotalPages(paginated_result?.pagination),
      })
    },
    []
  )

  const fetchProposals = useCallback(
    (props) => {
      services.bases.proposals
        .get({
          ...filters,
          business_id,
          pagination: {
            quantity: 8,
            page: props?.page,
          },
        })
        .then(handleFetch)
    },

    [business_id, filters, handleFetch]
  )

  useEffect(() => {
    fetchProposals()
  }, [fetchProposals])

  const onFilters = (filter) => {
    setFilters({ ...filters, ...filter })
  }

  const onUpdate = () => {
    services.bases.proposals.update({ business_id }).then(handleFetch)
  }

  return {
    proposals,
    lastUpdated,
    status,
    pagination,
    onFilters,
    onUpdate,
    refresh: fetchProposals,
  }
}

export default useProposals
