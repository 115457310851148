import If from "components/If"
import classnames from "classnames"

import "./BadgeStatus.styl"

const BadgeStatus = ({
  className,
  status = "1",
  description = "",
  ...props
}) => (
  <If condition={description}>
    <span
      className={classnames("badge-status", className)}
      id={status}
      {...props}
    >
      {description}
    </span>
  </If>
)

export default BadgeStatus
