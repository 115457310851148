import Icon from "components/Icon"
import If from "components/If"
import classnames from "classnames"

import usePagination from "./hooks"

import "./Pagination.styl"

const Pagination = ({ currentPage = 1, pages = [1], onChange }) => {
  const { formattedPages, isActive, changePage, nextPage, backPage } =
    usePagination(currentPage, pages, onChange)

  return (
    <If condition={pages.length > 1}>
      <ul className="pagination" data-test="pagination">
        <li className="pagination__arrow pagination__arrow--previous">
          <button
            onClick={backPage}
            data-test="page-previous"
            className="pagination__button"
          >
            <Icon className="pagination__icon" name="arrow-previous" />
          </button>
        </li>
        {formattedPages.map((pageNumber, index) => (
          <li
            className={classnames("pagination__number", {
              "pagination__number--active": isActive(pageNumber),
            })}
            key={index}
          >
            <If
              condition={typeof pageNumber === "number"}
              renderIf={
                <button
                  className="pagination__button"
                  data-test={`page-number-${pageNumber}`}
                  data-page={pageNumber}
                  onClick={changePage}
                >
                  {pageNumber}
                </button>
              }
              renderElse={pageNumber}
            />
          </li>
        ))}
        <li className="pagination__arrow pagination__arrow--next">
          <button
            className="pagination__button"
            data-test="page-next"
            onClick={nextPage}
          >
            <Icon className="pagination__icon" name="arrow-default" />
          </button>
        </li>
      </ul>
    </If>
  )
}

export default Pagination
