import Icon from "components/Icon"

import "./TableModal.styl"

const TableModal = ({ children, props }) => {
  return (
    <td className="table-modal" {...props} data-test="ballon-table">
      <Icon name="dots" />
      <ul className="table-modal__popup" data-test="ballon-list">
        {children}
      </ul>
    </td>
  )
}

export default TableModal
