import If from "components/If"
import Card from "components/Card"
import Icon from "components/Icon"
import classnames from "classnames"
import Text from "components/Typography/Text"
import Title from "components/Typography/Title"

import "./PopUp.styl"

const Button = ({ isSecondary = false, text = "", onClick = (e) => e }) => (
  <button
    className={classnames("popup__button", {
      "popup__button--secondary": isSecondary,
    })}
    onClick={onClick}
  >
    {text}
  </button>
)

const PopUp = ({
  icon,
  title,
  button,
  children,
  className,
  description,
  visibility = true,
  ...props
}) => (
  <If condition={visibility}>
    <div className={classnames("popup", className)} {...props}>
      <Card spacing="none" className="popup__card">
        <If condition={icon}>
          <Icon name={icon} className="popup__icon" />
        </If>
        <If condition={title}>
          <Title variant="small">{title}</Title>
        </If>
        <If condition={description}>
          <Text variant="normal">{description}</Text>
        </If>

        <div className="popup__actions">
          <If
            condition={children}
            renderIf={<>{children}</>}
            renderElse={<Button {...button} />}
          />
        </div>
      </Card>
    </div>
  </If>
)

export default PopUp
