import { BadgeStatus } from "components/Badge"
import Card from "components/Card"
import EmptyState from "components/EmptyState"
import If from "components/If"

import "./Status.styl"

const ProposalsStatus = ({ status }) => {
  return (
    <Card className="proposals-status" data-test="contador de status">
      <If
        condition={status?.length}
        renderIf={
          <ul className="proposals-status__list">
            {status?.map((item) => (
              <li
                key={item.status_id}
                data-test="status item"
                className="proposals-status__item"
              >
                <BadgeStatus
                  className="proposals-status__badge"
                  data-test="status contável"
                  description={item?.status_name}
                  status={item?.color}
                />
                <span className="proposals-status__count">{item?.count}</span>
              </li>
            ))}
          </ul>
        }
        renderElse={<EmptyState className="proposals-status__empty" />}
      />
    </Card>
  )
}

export default ProposalsStatus
