import { Mobile } from "components/Devices"
import If from "components/If"
import { TableItem } from "components/Table"
import useAccordion from "./hooks"
import "./TableListAccordion.styl"

const TableListAccordion = ({
  children,
  toggleText,
  withoutPadding,
  InfoComponent = () => <div />,
}) => {
  const { isOpen, ToggleComponent, toggleAccordionState } = useAccordion({
    toggleText,
  })

  return (
    <Mobile>
      <If condition={isOpen}>{children}</If>

      <TableItem withoutPadding={withoutPadding}>
        <div className="table-list-accordion">
          <ToggleComponent
            aria-label="Expandir"
            onClick={toggleAccordionState}
            className="table-list-accordion__toggle"
          />

          <div className="table-list-accordion__info">
            <InfoComponent />
          </div>
        </div>
      </TableItem>
    </Mobile>
  )
}

export default TableListAccordion
