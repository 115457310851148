import Button from "components/Button"
import Icon from "components/Icon"
import If from "components/If"
import { BadgeStatus } from "components/Badge"

import "./ProposalDetailCard.styl"

const ProposalDetailCard = ({
  proposalCard,
  handlePopupVisible,
  handleProposalCardDetail,
}) => {
  const isInProgressStatus = proposalCard?.status_id === 6 // Status "Em andamento"

  return (
    <>
      <div
        className="proposal-detail-card__mobile-overlay"
        onClick={() => handleProposalCardDetail(null)}
      ></div>

      <div className="proposal-detail-card" data-test="details-card">
        <div>
          <button
            title="Fechar detalhes da proposta"
            className="proposal-detail-card__close-button"
            onClick={() => handleProposalCardDetail(null)}
          >
            <Icon
              name="close"
              className="proposal-detail-card__close-icon"
              aria-hidden="true"
            />
          </button>

          <div className="proposal-detail-card__wrapper">
            <p className="proposal-detail-card__title">Dados do cliente</p>

            <div className="proposal-detail-card__name">
              <div className="proposal-detail-card__name-item">
                <p className="proposal-detail-card__subtitle">
                  Nome do Cliente
                </p>
                <p className="proposal-detail-card__text">
                  {proposalCard.name}
                </p>
              </div>
              <div className="proposal-detail-card__name-item">
                <p className="proposal-detail-card__subtitle">CPF</p>
                <p className="proposal-detail-card__text">
                  {proposalCard.document_number}
                </p>
              </div>
            </div>
          </div>

          <div className="proposal-detail-card__wrapper">
            <p className="proposal-detail-card__title">Descrição do status</p>
            <p className="proposal-detail-card__subtitle">
              {proposalCard.substatus}
            </p>
          </div>

          <If condition={proposalCard?.status_steps?.length}>
            <div
              className="proposal-detail-card__wrapper"
              data-test="details-card-propostal-step-card"
            >
              <p className="proposal-detail-card__title">Etapas da proposta</p>

              {proposalCard?.status_steps?.map((item, index) => (
                <p
                  className="proposal-detail-card__text-badge"
                  data-test="details-card-propostal-step-text"
                  key={`${item.step_description}-${index}`}
                >
                  {item.step_description}
                  <BadgeStatus
                    description={item.step_status}
                    status={item.status_color}
                  />
                </p>
              ))}
            </div>
          </If>

          <If condition={isInProgressStatus}>
            <div className="proposal-detail-card__wrapper">
              <Button
                variant="ghost"
                onClick={handlePopupVisible}
                data-test="cancel-proposal-details"
              >
                Cancelar proposta
              </Button>

              <Button
                variant="link"
                onClick={() => handleProposalCardDetail(null)}
              >
                Fechar
              </Button>
            </div>
          </If>
        </div>
      </div>
    </>
  )
}

export default ProposalDetailCard
