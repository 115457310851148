import classnames from "classnames"

import "./BadgeQuantity.styl"

const BadgeQuantity = ({ customClasses = "", quantity, variant }) => (
  <span
    className={classnames("badge-status", customClasses, {
      [`badge-quantity--${variant}`]: variant,
    })}
  >
    {quantity}
  </span>
)

export default BadgeQuantity
