import If from "components/If"
import Variant from "./Variant"

const Proposals = ({ pageContext: { business } }) => {
  return (
    <If
      condition={business?.business_name === "microcredito"}
      renderIf={<Variant.Microloans business={business} />}
      renderElse={<Variant.Default business={business} />}
    />
  )
}

export default Proposals
