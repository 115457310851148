import Form from "components/Forms/Form"
import TextArea from "components/Forms/TextArea"

import { values, validation } from "./form"
import "./CancelProposalForm.styl"

const CancelProposalForm = ({ handlePopupVisible, onSubmitCancelProposal }) => {
  return (
    <Form
      className="cancel-proposal-form"
      defaultValues={values}
      manually={true}
      onSubmit={onSubmitCancelProposal}
      schema={validation}
    >
      {({ form, state: { isDisabled } }) => (
        <>
          <div className="cancel-proposal-form__description-wrapper">
            <p className="cancel-proposal-form__title">Cancelar proposta</p>
            <p className="cancel-proposal-form__text">
              Informe o motivo do cancelamento
            </p>

            <TextArea name="reason" form={form} data-test="cancel-reason" />
          </div>

          <div className="cancel-popup">
            <button
              className="confirm-popup__cancel"
              onClick={handlePopupVisible}
              type="button"
            >
              Fechar
            </button>
            <button
              data-test="cancel-proposal-popup"
              className="confirm-popup__close"
              disabled={isDisabled}
              type="submit"
            >
              Cancelar proposta
            </button>
          </div>
        </>
      )}
    </Form>
  )
}

export default CancelProposalForm
