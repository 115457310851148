import If from "components/If"
import Can from "components/Can"
import PopUp from "components/PopUp"
import Button from "components/Button"
import Text from "components/Typography/Text"
import PageHeader from "components/PageHeader"
import ProposalsTable from "../../components/Table"
import ProposalsFilters from "../../components/Filters"
import CancelProposalForm from "./components/CancelProposalForm"
import ProposalDetailCard from "./components/ProposalDetailCard"

import useProposals from "./hooks"

import "./Proposals.styl"

const Proposals = ({ business }) => {
  const {
    proposals,
    lastUpdated,
    status,
    pagination,
    onFilters,
    onUpdate,
    refresh,

    proposalCard,
    handleProposalCardDetail,
    handlePopupVisible,
    isCancelProposalFormOpen,
    isPopupOpen,
    onSubmitCancelProposal,
    popupMessage,
  } = useProposals(business)

  return (
    <Can
      business={business.business_permission}
      see="proposals"
      otherwise={{ goTo: ROUTES.home.path }}
    >
      <header className="proposals-microloans__header">
        <PageHeader title="Gestão de propostas" />
        <div className="proposals-microloans__info">
          <Text className="proposals-microloans__last-updated-at">
            {lastUpdated}
          </Text>
          <Button
            className="proposals-microloans__update"
            icon="union"
            variant="group"
            onClick={onUpdate}
          >
            Atualizar
          </Button>
        </div>
      </header>
      <div className="proposals-microloans">
        <ProposalsFilters
          variation="microloans"
          onFilters={onFilters}
          status={status}
        />

        <div className="proposals-microloans-table-wrapper">
          <ProposalsTable
            variation="microloans"
            proposals={proposals}
            onPagination={refresh}
            pagination={pagination}
            proposalCard={proposalCard}
            handleProposalCardDetail={handleProposalCardDetail}
          />

          <If condition={!!proposalCard}>
            <ProposalDetailCard
              proposalCard={proposalCard}
              handlePopupVisible={handlePopupVisible}
              handleProposalCardDetail={handleProposalCardDetail}
            />
          </If>
        </div>
      </div>

      <PopUp
        visibility={isPopupOpen}
        className="proposals-microloans-popup"
        {...popupMessage}
      >
        {isCancelProposalFormOpen && (
          <CancelProposalForm
            handlePopupVisible={handlePopupVisible}
            onSubmitCancelProposal={onSubmitCancelProposal}
          />
        )}
      </PopUp>
    </Can>
  )
}

export default Proposals
