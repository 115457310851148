export const HEADERS = ["Nome/CPF", "Data da proposta", "Telefone", "Status"]

export const CANCEL_POPUP_INFO = {
  error: {
    icon: "error",
    title: "Erro ao cancelar a proposta!",
    description: (message = null) =>
      message || "A proposta não pode ser cancelada.",
  },
  success: {
    icon: "success-outlined",
    title: "Aguarde enquanto a proposta é cancelada",
    description: (message) =>
      "A proposta está em processo de cancelamento e pode demorar alguns minutos.",
  },
}
