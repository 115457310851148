import Icon from "components/Icon"
import { useCallback, useState } from "react"
import { TOGGLE_IMAGE_STATE, TOGGLE_TEXT_STATE } from "./constants"

const useTableListAccordion = ({ toggleText = TOGGLE_TEXT_STATE[false] }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [toggleLabel, setToggleLabel] = useState(toggleText)

  const toggleAccordionState = useCallback(() => {
    const is_open = !isOpen

    setIsOpen(is_open)
    setToggleLabel(TOGGLE_TEXT_STATE[is_open])
  }, [isOpen])

  return {
    isOpen,
    toggleAccordionState,
    ToggleComponent: ({ ...props }) => (
      <div {...props}>
        {toggleLabel}{" "}
        <Icon
          name="arrow-down"
          className={`table-list-accordion__toggle--icon ${TOGGLE_IMAGE_STATE[isOpen]}`}
        />
      </div>
    ),
  }
}

export default useTableListAccordion
