import Can from "components/Can"
import Button from "components/Button"
import Text from "components/Typography/Text"
import PageHeader from "components/PageHeader"
import ProposalsStatus from "./components/Status"
import ProposalsTable from "../../components/Table"
import ProposalsFilters from "../../components/Filters"

import useProposals from "./hooks"

import "./Proposals.styl"

const Proposals = ({ business }) => {
  const {
    status,
    refresh,
    onUpdate,
    onFilters,
    proposals,
    pagination,
    lastUpdated,
  } = useProposals(business)

  return (
    <Can
      business={business.business_permission}
      see="proposals"
      otherwise={{ goTo: ROUTES.home.path }}
    >
      <header className="proposals__header">
        <PageHeader title="Gestão de propostas" />
        <div className="proposals__info">
          <Text className="proposals__last-updated-at">{lastUpdated}</Text>
          <Button
            className="proposals__update"
            icon="union"
            variant="group"
            onClick={onUpdate}
          >
            Atualizar
          </Button>
        </div>
      </header>
      <div className="proposals">
        <ProposalsStatus status={status} />
        <ProposalsFilters onFilters={onFilters} status={status} />
        <ProposalsTable
          proposals={proposals}
          pagination={pagination}
          onPagination={refresh}
        />
      </div>
    </Can>
  )
}

export default Proposals
