import { useState } from "react"

const useTextArea = () => {
  const [countChars, setCountChars] = useState(0)

  const handleValueSizeChange = (e) =>
    setCountChars(e?.target?.value.length || 0)

  return { handleValueSizeChange, countChars }
}

export default useTextArea
