import If from "components/If"
import Card from "components/Card"
import classnames from "classnames"
import EmptyState from "components/EmptyState"
import Pagination from "components/Pagination"
import { BadgeStatus } from "components/Badge"
import { Table, TableItem, TableList } from "components/Table"

import { HEADERS } from "./constants"

import "./Table.styl"

const ProposalsTable = ({
  pagination,
  onPagination,
  proposalCard,
  proposals = [],
  variation = "default",
  handleProposalCardDetail = (e) => e,
}) => {
  return (
    <div
      className={classnames("proposals-table", {
        [`proposals-table__variation--${variation}`]: !!variation,
      })}
    >
      <If
        condition={proposals?.length}
        renderIf={
          <Table headers={HEADERS}>
            {proposals?.map((proposal, index) => (
              <TableList
                key={index}
                isActive={proposalCard?.external_id === proposal?.external_id}
                data-test="proposals-row"
                onClick={() => handleProposalCardDetail(proposal)}
              >
                <TableItem data-label="Nome/CPF" data-test="nome">
                  {proposal.name}
                  <br />
                  <span className="document">{proposal.document_number}</span>
                </TableItem>
                <TableItem
                  className="proposal-date"
                  data-test="data da proposta"
                  data-label="Data da proposta"
                >
                  {proposal.proposal_date}
                </TableItem>
                <TableItem data-label="Telefone">
                  <a
                    data-test="telefone"
                    href={`tel:+55${proposal.phone_number}`}
                  >
                    {proposal.phone_number}
                  </a>
                </TableItem>
                <TableItem data-label="Status">
                  <BadgeStatus
                    description={proposal.status}
                    status={proposal.status_color}
                    data-test="status da proposta"
                  />
                </TableItem>
              </TableList>
            ))}
          </Table>
        }
        renderElse={
          <Card className="proposals-table__card">
            <EmptyState
              className="proposals-table__empty"
              text="Nenhuma proposta"
            />
          </Card>
        }
      />
      <Pagination
        currentPage={pagination?.current_page}
        pages={pagination?.total_pages}
        onChange={onPagination}
      />
    </div>
  )
}

export default ProposalsTable
