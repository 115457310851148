import classnames from "classnames"
import If from "components/If"
import { Link } from "gatsby"

import "./TableItem.styl"

const TableItem = ({
  className,
  link,
  color,
  withoutPadding,
  children,
  ...props
}) => {
  return (
    <td
      className={classnames("table-item", {
        "table-item--link": link,
        "table-item--without-padding": withoutPadding,
        [`table-item--color--${color}`]: color,
        [className]: className,
      })}
      {...props}
    >
      <If
        condition={link}
        renderIf={<Link to={link}>{children}</Link>}
        renderElse={children}
      />
    </td>
  )
}

export default TableItem
