import classnames from "classnames"

import "./TableList.styl"

const TableList = ({
  children,
  className = "",
  isActive = false,
  ...props
}) => {
  return (
    <tr
      className={classnames(`table-list`, {
        [className]: !!className,
        "table-list__active": isActive,
      })}
      {...props}
    >
      {children}
    </tr>
  )
}

export default TableList
