import classnames from "classnames"

import "./Table.styl"

const Table = ({ className, header, headers = [], children }) => {
  return (
    <table className={classnames("table", className)} data-test="table">
      <tbody>
        <tr className="table__header">
          {headers.map((item) => (
            <th className={classnames("table__header-item", item)} key={item}>
              {item}
            </th>
          ))}
        </tr>
        {children}
      </tbody>
    </table>
  )
}

export default Table
