import classnames from "classnames"
import Form from "components/Forms/Form"
import Input from "components/Forms/Input"
import Select from "components/Forms/Select"

import useFilters from "./hooks"

import "./Filters.styl"
import DateTime from "components/Forms/DateTime"

const ProposalsFilters = ({ onFilters, status, variation = "default" }) => {
  const { onSearch, onFilterByStatus, onFilterByDate } = useFilters({
    onFilters,
  })

  return (
    <div
      className={classnames("proposals-filters", {
        [`proposals-filters__variation--${variation}`]: !!variation,
      })}
    >
      <Form className="proposals-filters__form">
        <Input
          variant="small"
          className="proposals-filters__search"
          name="search"
          placeholder="Buscar por nome"
          icon="search"
          onChange={(e) => onSearch(e?.target)}
          margin="false"
        />
        <Select
          className="proposals-filters__status"
          variant="small"
          name="status"
          margin="false"
          onChange={onFilterByStatus}
          defaultValue=""
        >
          <option value="">Todos os status</option>
          {status?.map((item) => (
            <option key={item?.status_id} value={item?.status_id}>
              {item?.status_name}
            </option>
          ))}
        </Select>
        <DateTime
          className="proposals-filters__date"
          name="date"
          variant="small"
          onChange={onFilterByDate}
        />
      </Form>
    </div>
  )
}

export default ProposalsFilters
