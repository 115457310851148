import debounce from "utils/debounce"
import helpers from "utils/helpers"
import date from "utils/date"

const useFilters = ({ onFilters }) => {
  const onSearch = debounce((target) => {
    const search = helpers.isSearchable(target?.value) ? target?.value : ""
    onFilters({ search, hideLoader: true })
  }, 1000)

  const onFilterByStatus = ({ target: { value } }) => {
    onFilters({ status: value, hideLoader: false })
  }

  const onFilterByDate = ({ target: { value } }) => {
    const proposal = value && date.toDateString(value)

    onFilters({ proposal_date: proposal, hideLoader: false })
  }

  return {
    onSearch,
    onFilterByStatus,
    onFilterByDate,
  }
}

export default useFilters
