import { useCallback, useEffect, useState } from "react"
import _pagination from "utils/pagination"
import services from "services"
import { CANCEL_POPUP_INFO } from "./constants"

const useProposals = ({ business_id = "1" }) => {
  const [lastUpdated, setLastUpdated] = useState(null)
  const [pagination, setPagination] = useState({})
  const [proposals, setProposals] = useState([])
  const [filters, setFilters] = useState({})
  const [status, setStatus] = useState([])

  const [isCancelProposalFormOpen, setIsCancelProposalFormOpen] = useState(true)
  const [proposalCard, setProposalCard] = useState(null)
  const [popupMessage, setPopupMessage] = useState({})
  const [isPopupOpen, setPopupOpen] = useState(false)

  const handleProposalCardDetail = (proposal) => {
    setProposalCard(proposal)
  }

  const handleCancelProposalFormVisible = () => {
    setIsCancelProposalFormOpen(!isCancelProposalFormOpen)
  }

  const handlePopupVisible = () => {
    setPopupOpen(!isPopupOpen)
    setIsCancelProposalFormOpen(true)
    setPopupMessage({})
  }

  const onSubmitCancelProposal = (values, actions) => {
    const { reason } = values
    const { external_id } = proposalCard

    services.bases.proposals
      .cancel({ business_id, external_id, reason })
      .then((value) => {
        handleCancelProposalFormVisible()
        const popupVariation = value?.error ? "error" : "success"

        const popupMsg = {
          icon: CANCEL_POPUP_INFO[popupVariation].icon,
          title: CANCEL_POPUP_INFO[popupVariation].title,
          description: CANCEL_POPUP_INFO[popupVariation].description(
            value?.message
          ),
          button: {
            text: "Fechar",
            onClick: handlePopupVisible,
            className: "confirm-bucket-popup__close",
          },
        }

        setPopupMessage(popupMsg)
      })
  }

  const handleFetch = useCallback(
    ({ last_updated_at, status_count, paginated_result }) => {
      setLastUpdated(last_updated_at)
      setStatus(status_count)
      setProposals(paginated_result?.data)
      setPagination({
        ...paginated_result?.pagination,
        total_pages: _pagination.getTotalPages(paginated_result?.pagination),
      })
    },
    []
  )

  const fetchProposals = useCallback(
    (props) => {
      services.bases.proposals
        .get({
          ...filters,
          business_id,
          pagination: {
            quantity: 8,
            page: props?.page,
          },
        })
        .then(handleFetch)
    },

    [business_id, filters, handleFetch]
  )

  useEffect(() => {
    fetchProposals()
  }, [fetchProposals])

  const onFilters = (filter) => {
    setFilters({ ...filters, ...filter })
  }

  const onUpdate = () => {
    services.bases.proposals.update({ business_id }).then(handleFetch)
  }

  return {
    status,
    onUpdate,
    proposals,
    onFilters,
    pagination,
    lastUpdated,
    isPopupOpen,
    popupMessage,
    proposalCard,
    handlePopupVisible,
    onSubmitCancelProposal,
    refresh: fetchProposals,
    handleProposalCardDetail,
    isCancelProposalFormOpen,
  }
}

export default useProposals
