import If from "components/If"
import useTextArea from "./hooks"

import "./TextArea.styl"

const TextArea = ({ name, form: { register }, defaultValue, ...props }) => {
  const { handleValueSizeChange, countChars } = useTextArea()

  return (
    <div className="form-textarea">
      <textarea
        defaultValue={defaultValue}
        onKeyUp={handleValueSizeChange}
        className="form-textarea__control"
        {...register(name)}
        {...props}
      />
      <If condition={props?.maxlength}>
        <span
          className="form-textarea__maxlength"
          data-test="textarea-maxlength"
        >
          {countChars}/{props?.maxlength}
        </span>
      </If>
    </div>
  )
}

export default TextArea
