import { getPageDistance } from "./utils"

const usePagination = (currentPage, pages, onChange = (e) => e) => {
  const isActive = (number) => number === currentPage

  const changePage = (event) => {
    const { page } = event.target.dataset

    onChange({ page })
  }

  const nextPage = () => {
    const nextPage = currentPage + 1

    if (nextPage > pages.length) return

    onChange({ page: nextPage })
  }

  const backPage = () => {
    const backPage = currentPage - 1

    if (backPage < 1) return

    onChange({ page: backPage })
  }

  const formatPages = () => {
    if (pages.length < 7) {
      return pages
    }

    const formattedPages = pages.reduce((collection, page) => {
      const distance = getPageDistance(currentPage, page)
      const rules = [page === 1 || page === pages.length, distance <= 2]
      const shouldAddPage = rules.reduce(
        (result, expression) => result || expression,
        false
      )

      if (shouldAddPage) return [...collection, page]

      if (typeof collection[collection.length - 1] === "number")
        return [...collection, "..."]

      return collection
    }, [])

    return formattedPages
  }

  return {
    formattedPages: formatPages(),
    isActive,
    changePage,
    nextPage,
    backPage,
  }
}

export default usePagination
